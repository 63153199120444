var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('hr'),(!_vm.loading)?_c('div',[_c('form',{staticClass:"mb-4 border p-3",attrs:{"id":"users_form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('h5',{staticClass:"underlined"},[_vm._v("Add User")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"form-floating"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.add_user.email),expression:"add_user.email"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"User's Email","name":"email"},domProps:{"value":(_vm.add_user.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.add_user, "email", $event.target.value)}}}),_c('label',[_vm._v("User's Email")])])]),_c('div',{staticClass:"col-md-5"},[_c('vue-select',{attrs:{"options":_vm.roles,"label":"name","target":"id","placeholder":"Select Role"},model:{value:(_vm.add_user.role),callback:function ($$v) {_vm.$set(_vm.add_user, "role", $$v)},expression:"add_user.role"}})],1),_vm._m(1)])]),_vm._m(2),_c('div',{staticClass:"mt-4"},[_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
            name: 'dashboard.organizations.projects.create.location',
          })}}},[_c('i',{staticClass:"fa fa-arrow-left me-2"}),_vm._v("Back")]),_vm._m(3)])]):_vm._e(),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"centered"},[_c('i',{staticClass:"fad fa-users",staticStyle:{"font-size":"30px"}})]),_c('div',{staticClass:"ps-3"},[_c('h4',{staticClass:"mb-0"},[_vm._v("Authorized Representatives")]),_c('p',{staticClass:"text-muted mb-0"},[_vm._v(" A more detailed information about your project ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 centered"},[_c('button',{staticClass:"btn btn-primary btn-sm w-100"},[_c('i',{staticClass:"fa fa-plus me-2"}),_vm._v("Add User ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Email")]),_c('th',[_vm._v("Role")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Action")])])]),_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_vm._v("There is no data to display")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-primary btn-sm float-end"},[_vm._v(" Proceed"),_c('i',{staticClass:"fa fa-arrow-right ms-2"})])
}]

export { render, staticRenderFns }