<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="fad fa-thumbs-up" style="font-size: 30px"></i>
      </div>
      <div class="ps-3">
        <h4 class="mb-0">Other Benefits</h4>
        <p class="text-muted mb-0">
          Represents a net environmental benefit and real mitigation of GHG
          emissions in excess of the baseline scenario.
        </p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submit" v-if="!loading" id="benefits_form">
      <div class="row">
        <div
          class="col-md-4 mb-2"
          v-for="(benefit, i) in benefits"
          :key="`benefit-${i}`"
        >
          <div class="card border">
            <div class="card-body">
              <div class="float-end">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="benefit.id"
                    v-model="project.benefit_ids"
                  />
                </div>
              </div>
              <div class="d-flex">
                <img
                  :src="benefit.icon"
                  :alt="benefit.name"
                  style="height: 30px"
                />
                <h6 class="ms-3">{{ benefit.name }}</h6>
              </div>
              <p>{{ benefit.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <a
          href="#"
          @click.prevent="
            $router.push({
              name: 'dashboard.organizations.projects.create.additionality',
            })
          "
          class="btn btn-primary btn-sm"
          ><i class="fa fa-arrow-left me-2"></i>Back</a
        >
        <button class="btn btn-primary btn-sm float-end">
          Proceed<i class="fa fa-arrow-right ms-2"></i>
        </button>
      </div>
    </form>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      benefits: [],
      loading: true,
      project: { benefit_ids: [] }
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/dashboard/my-projects/create?step=5&org_id=${this.$route.params.id}`)
        .then((response) => {
          this.benefits = response.data.benefits;

          if(response.data.project.start_date == null) {
            return this.$router.push({ name: 'dashboard.organizations.projects.create.details' })
          }

          this.loading = false;
        });
    },

    submit() {
      let data = {
        benefit_ids: this.project.benefit_ids,
        form_id: 'benefits_form',
        org_id: this.$route.params.id,
        step: 5
      }

      this.$axios.post('/api/v1/dashboard/my-projects', data).then(() => {
        this.$router.push({ name: 'dashboard.organizations.projects.create.transition' })
      })
    }
  },
};
</script>
