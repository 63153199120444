<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="fad fa-file-alt" style="font-size: 30px"></i>
      </div>
      <div class="ps-3">
        <h4 class="mb-0">Project Details</h4>
        <p class="text-muted mb-0">
          A more detailed information about your project
        </p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submit" id="details_form" v-if="!loading">
      <div class="row">
        <div class="col-md-4 mb-4">
          <label>Project Start Date</label>
          <date-picker
            v-model="project.start_date"
            name="start_date"
            inputClass="form-control"
            placeholder="Project Start Date"
          />
        </div>
        <div class="col-md-4 mb-4">
          <vue-select
            :options="sectors"
            label="name"
            v-model="project.sector_id"
            target="id"
            placeholder="Project Sector"
            name="sector_id"
          />
        </div>
        <div class="col-md-4 mb-4">
          <label>Do you apply any Methodology?</label>
          <select
            class="form-control"
            v-model="project.methodology"
            name="methodology"
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </div>
      <div class="row">
        <h6 class="mb-1">GHG Program</h6>
        <div>
          <input type="hidden" name="ghg_ids" class="form-control" />
        </div>
        <div class="row">
          <div class="col-md-6" v-for="(ghg, i) in ghgs" :key="`ghg-${i}`">
            <div class="card border">
              <div class="card-body">
                <input
                  type="checkbox"
                  :id="`ghg-${i}`"
                  class="me-3"
                  :value="ghg.id"
                  v-model="project.ghg_ids"
                />
                <label :for="`ghg-${i}`"
                  ><h6 class="mb-1">
                    {{ ghg.name }}
                  </h6></label
                >
                <label :for="`ghg-${i}`">{{ ghg.description }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <a
            href="#"
            @click.prevent="
              $router.push({
                name: 'dashboard.organizations.projects.create.summary',
              })
            "
            class="btn btn-primary btn-sm"
            ><i class="fa fa-arrow-left me-2"></i>Back</a
          >
          <button class="btn btn-primary btn-sm float-end">
            Proceed<i class="fa fa-arrow-right ms-2"></i>
          </button>
        </div>
      </div>
    </form>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      project: { methodology: 0, ghg_ids: [] },
      loading: true,
      sectors: [],
      ghgs: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/dashboard/my-projects/create?step=3&org_id=${this.$route.params.id}`)
        .then((response) => {
          this.sectors = response.data.sectors;
          this.ghgs = response.data.ghgs;

          if(response.data.project.name == null) {
            this.$router.push({ name: 'dashboard.organizations.projects.create.summary' })
          }

          if(response.data.project.start_date) {
            this.project = response.data.project
          }

          this.loading = false;
        });
    },

    submit() {
      let data = {
        start_date: this.project.start_date,
        sector_id: this.project.sector_id,
        methodology: this.project.methodology,
        ghg_ids: this.project.ghg_ids,
        org_id: this.$route.params.id,
        form_id: "details_form",
        step: 3,
      };

      this.$axios.post("/api/v1/dashboard/my-projects", data).then(() => {
        this.$router.push({ name: 'dashboard.organizations.projects.create.additionality' })
      });
    },
  },
};
</script>
