<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="fad fa-cogs" style="font-size: 30px"></i>
      </div>
      <div class="ps-3">
        <h4 class="mb-0">Project Mitigations</h4>
        <p class="text-muted mb-0">
          What are the details about the impacts of your project and how long do
          you intend to claim credits for your project.
        </p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submit" id="mitigations_form">
      <h6>GHG Program</h6>
      <div>
        <input type="hidden" name="ghg_program" class="form-control">
      </div>
      <div class="row">
        <div class="col-md-4 mb-4">
          <div class="border">
            <input type="radio" id="ghg-1" class="mx-2" value="1" v-model="project.ghg_program" name="ghg_program" />
            <label for="ghg-1" class="ms-2 border-start ps-2 py-2"
              >Avoidance / Reduction</label
            >
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="border">
            <input type="radio" id="ghg-2" class="mx-2" value="2" v-model="project.ghg_program" name="ghg_program"  />
            <label for="ghg-2" class="ms-2 border-start ps-2 py-2"
              >Sequestration / Removal</label
            >
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="border">
            <input type="radio" id="ghg-3" class="mx-2" value="3" v-model="project.ghg_program" name="ghg_program"  />
            <label for="ghg-3" class="ms-2 border-start ps-2 py-2"
              >Hybrid both</label
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="Crediting period in years"
              name="credit_period"
              v-model="project.credit_period"
            />
            <label>Crediting period in years</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="Estimated annual mitigations"
              name="estimated_mitigations"
              v-model="project.estimated_mitigations"
            />
            <label>Estimated annual mitigations</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="Please provide MRV Cycle information"
              name="mrv_cycle"
              v-model="project.mrv_cycle"
            />
            <label>MRV Cycle information</label>
          </div>
        </div>
        <div class="col-md-8">
          <table class="table mt-3">
            <tbody>
              <tr>
                <td>
                  <strong>Do you intend to renew the crediting period?</strong>
                </td>
                <td>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input floet-end"
                      type="checkbox"
                      id="defaultCheckbox1"
                      v-model="project.renew"
                    />
                    <label class="form-check-label" for="defaultCheckbox1">
                      <span v-if="project.renew">Yes</span>
                      <span v-if="!project.renew">No</span>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-4">
        <a
          href="#"
          @click.prevent="
            $router.push({
              name: 'dashboard.organizations.projects.create.validation',
            })
          "
          class="btn btn-primary btn-sm"
          ><i class="fa fa-arrow-left me-2"></i>Back</a
        >
        <button class="btn btn-primary btn-sm float-end">
          Proceed<i class="fa fa-arrow-right ms-2"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      project: { ghg_program: 1 }
    };
  },

  methods: {
    submit() {
      let data = {
        form_id: 'mitigations_form',
        org_id: this.$route.params.id,
        step: 7,
        ghg_program: this.project.ghg_program,
        credit_period: this.project.credit_period,
        estimated_mitigations: this.project.estimated_mitigations,
        mrv_cycle: this.project.mrv_cycle,
        renew: this.project.renew,
      }

      this.$axios.post('/api/v1/dashboard/my-projects', data).then(() => {
        this.$router.push({ name: 'dashboard.organizations.projects.create.location' })
      })
    }
  }
};
</script>
