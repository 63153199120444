<template>
  <div>
    <div v-if="! loading">
      <h4><i class="fal fa-file-check me-2"></i>Registration Form</h4>
      <hr />
      <p class="mb-1">
        Welcome to the registration form. In order to complete the process make
        sure you have completed or drafted relevant documentation (subject to
        the status of your implementation/operations)
      </p>
      <p>
        Here are documents that you may need during the registration process.
        Please make sure you have used relevant templates available on ICR
        website or below.
      </p>
      <div class="row">
        <div class="col-md-4 mb-3" v-for="(doc, i) in docs" :key="`doc-${i}`">
          <div class="card border text-center">
            <div class="card-body">
              <img :src="doc.icon" :alt="doc.name" style="width: 50px; height: 50px;">
              <h6 class="mt-3">{{ doc.name }}</h6>
              <p>{{ doc.description }}</p>
              <a :href="doc.document" target="_blank" class="btn bg-light-blue btn-sm"><i class="fal fa-download me-2"></i>Download</a>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 text-end">
          <a href="#" @click.prevent="$router.push({ name: 'dashboard.organizations.projects.create.summary' })" class="btn btn-primary btn-sm">Next<i class="fa fa-arrow-right ms-2"></i></a>
        </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      docs: []
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get(`/api/v1/dashboard/my-projects/create?step=1`).then(response => {
        this.docs = response.data.docs
        this.loading = false
      })
    },
  },
};
</script>
