<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="fad fa-check" style="font-size: 30px"></i>
      </div>
      <div class="ps-3">
        <h4 class="mb-0">Finish</h4>
        <p class="text-muted mb-0">
          Represents a net environmental benefit and real mitigation of GHG
          emissions in excess of the baseline scenario.
        </p>
      </div>
    </div>
    <hr />
    <form id="finish_form" @submit.prevent="submit">
      <div>
        <input type="checkbox" id="org-terms" class="me-2" v-model="terms" />
        <label for="org-terms"
          >I agree and approve the terms and conditions for listing of my
          climate project.</label
        >
      </div>
      <div class="mt-4">
        <a
          href="#"
          @click.prevent="
            $router.push({
              name: 'dashboard.organizations.projects.create.documentation',
            })
          "
          class="btn btn-primary btn-sm"
          ><i class="fa fa-arrow-left me-2"></i>Back</a
        >
        <button class="btn btn-primary btn-sm float-end" v-if="terms">
          Save & Continue<i class="fa fa-arrow-right ms-2"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      terms: false
    }
  },
  
  methods: {
    submit() {
      let data = {
        form_id: 'finish_form',
        org_id: this.$route.params.id,
        step: 12
      }

      this.$axios.post('/api/v1/dashboard/my-projects', data).then(() => {
        this.$router.push({ name: 'dashboard.organizations.show' })
      })
    }
  }
};
</script>
