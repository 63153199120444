<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="far fa-redo" style="font-size: 30px"></i>
      </div>
      <div class="ps-3">
        <h4 class="mb-0">Transition</h4>
        <p class="text-muted mb-0">
          Are you transitioning from other GHG program? Provide information
          about that registration.
        </p>
      </div>
    </div>
    <hr />
    <div>
      <form @submit.prevent="submit" id="transition_form">
        <table class="table">
          <tbody>
            <tr>
              <td>
                <strong
                  >Is the project transitioning from onother GHG program?</strong
                >
              </td>
              <td>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input floet-end"
                    type="checkbox"
                    id="defaultCheckbox1"
                    v-model="transitioning"
                  />
                  <label class="form-check-label" for="defaultCheckbox1">
                    <span v-if="transitioning">Yes</span>
                    <span v-if="!transitioning">No</span>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-4">
          <a
            href="#"
            @click.prevent="
              $router.push({
                name: 'dashboard.organizations.projects.create.benefits',
              })
            "
            class="btn btn-primary btn-sm"
            ><i class="fa fa-arrow-left me-2"></i>Back</a
          >
          <button class="btn btn-primary btn-sm float-end">
            Proceed<i class="fa fa-arrow-right ms-2"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transitioning: false
    }
  },

  methods: {
    submit() {
      let data = {
        form_id: 'transition_form',
        transitioning: this.transitioning,
        org_id: this.$route.params.id,
        step: 6
      }

      this.$axios.post('/api/v1/dashboard/my-projects', data).then(() => {
        this.$router.push({ name: 'dashboard.organizations.projects.create.validation' })
      })
    },
  },
};
</script>
