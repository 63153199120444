<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="fal fa-file-pdf" style="font-size: 30px"></i>
      </div>
      <div class="ps-3">
        <h4 class="mb-0">Documentation</h4>
        <p class="text-muted mb-0">
          A more detailed information about your project
        </p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submit" v-if="! loading" id="docs_form">
      <div class="row">
        <div class="col-md-4 mb-4" v-for="(doc, i) in docs" :key="`doc-${i}`">
          <h6>{{ doc.name }}</h6>
          <div class="border">
            <input type="file" class="form-control d-none" :ref="`upload_${doc.id}`" :name="`upload_${doc.id}`"  />
            <a href="#" @click.prevent="">
              <img src="/assets/doc-upload.png" alt="upload" />
            </a>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <h6>Other</h6>
          <div class="border">
            <input type="file" class="d-none" />
            <a href="#" @click.prevent="">
              <img src="/assets/doc-upload.png" alt="upload" />
            </a>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <a
          href="#"
          @click.prevent="
            $router.push({
              name: 'dashboard.organizations.projects.create.users',
            })
          "
          class="btn btn-primary btn-sm"
          ><i class="fa fa-arrow-left me-2"></i>Back</a
        >
        <button
          class="btn btn-primary btn-sm float-end"
          @click.prevent="submit"
        >
          Proceed<i class="fa fa-arrow-right ms-2"></i>
        </button>
      </div>
    </form>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: true,
      docs: []
    }
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get(`/api/v1/dashboard/my-projects/create?step=1`).then(response => {
        this.docs = response.data.docs
        this.loading = false
      })
    },

    submit_() {
      let data = new FormData
      data.append('form_id', 'docs_form')
      data.append('org_id', this.$route.params.id)
      data.append('step', 11)

      this.docs.forEach((doc, i) => {
        data.append(`doc[${i}]`, this.$refs[`upload_${doc.id}`]).files[0]
      })

      console.log(data)
    },

    submit() {
      this.$router.push({
        name: "dashboard.organizations.projects.create.finish",
      });
    },
  },
};
</script>
