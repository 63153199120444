<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="fal fa-map-marker" style="font-size: 30px"></i>
      </div>
      <div class="ps-3">
        <h4 class="mb-0">Project Location</h4>
        <p class="text-muted mb-0">
          A more detailed information about your project
        </p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submit" id="location_form" v-if="! loading">
      <div class="row">
        <div class="col-md-4 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="Street Name"
              name="street_name"
              v-model="project.street_name"
            />
            <label>Street Name</label>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="City"
              name="city"
              v-model="project.city"
            />
            <label>City</label>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="Zip Code"
              name="zip_code"
              v-model="project.zip_code"
            />
            <label>Zip Code</label>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="State / Province"
              name="state"
              v-model="project.state"
            />
            <label>State / Province</label>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="Country"
              name="country"
              v-model="project.country"
            />
            <label>Country</label>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="Geographic Region"
              name="region"
              v-model="project.region"
            />
            <label>Geographic Region</label>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <a
          href="#"
          @click.prevent="
            $router.push({
              name: 'dashboard.organizations.projects.create.mitigations',
            })
          "
          class="btn btn-primary btn-sm"
          ><i class="fa fa-arrow-left me-2"></i>Back</a
        >
        <button class="btn btn-primary btn-sm float-end">
          Proceed<i class="fa fa-arrow-right ms-2"></i>
        </button>
      </div>
    </form>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      project: {},
      loading: true
    };
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/dashboard/my-projects/create?step=9&org_id=${this.$route.params.id}`).then((response) => {
        if(response.data.project.start_date == null) {
          this.$router.push({ name: 'dashboard.organizations.projects.create.details' })
        }

        if(response.data.project.street_name) {
          this.project = response.data.project
        }

        this.loading = false
      })
    },

    submit() {
      let data = {
        street_name: this.project.street_name,
        city: this.project.city,
        zip_code: this.project.zip_code,
        state: this.project.state,
        country: this.project.country,
        region: this.project.region,
        org_id: this.$route.params.id,
        form_id: "location_form",
        step: 9,
      };

      this.$axios.post("/api/v1/dashboard/my-projects", data).then(() => {
        this.$router.push({
          name: "dashboard.organizations.projects.create.users",
        });
      });
    },
  },
};
</script>
