<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="fa fa-home me-2 text-info" style="font-size: 40px"></i>
      </div>
      <div>
        <h1 class="h3 mb-0 text-gray-800">Organization Onboarding</h1>
        <p class="text-muted mb-0">Register a new organization.</p>
      </div>
    </div>

    <div class="card mt-3" id="organization_form">
      <div class="card-body" v-if="!loading">
        <div class="cover-image">
          <image-picker
            placeholder="/theme/default-cover.png"
            id="organization_cover"
            name="organization_cover_image"
            imageClass="org-cover-image"
            v-model="org.cover"
          />
        </div>
        <div class="main-info">
          <div class="d-flex">
            <div style="max-width: 150px; max-height: 150px">
              <image-picker
                placeholder="/assets/organization.png"
                id="organization_logo"
                name="organization_logo_image"
                imageClass="org-logo-image"
                v-model="org.logo"
              />
            </div>
            <div class="centered w-100">
              <div class="ms-3 w-100">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control w-100"
                    id="name"
                    placeholder="Organization Name"
                    name="name"
                    v-model="org.name"
                  />
                  <label for="name">Organization Name</label>
                </div>
              </div>
            </div>
          </div>
          <h5 class="mt-4">Organization Details</h5>
          <hr />
          <div class="row">
            <div class="col-md-4 mb-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="registration"
                  placeholder="Registration Number"
                  name="registration_number"
                  v-model="org.registration_number"
                />
                <label for="registration">Registration Number</label>
              </div>
            </div>
            <div class="col-md-4 mb-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="Email"
                  name="email"
                  v-model="org.email"
                />
                <label for="email">Email</label>
              </div>
            </div>
            <div class="col-md-4 mb-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  placeholder="Organization Address"
                  name="address"
                  v-model="org.address"
                />
                <label for="address">Organization Address</label>
              </div>
            </div>
            <div class="col-md-3 mb-4">
              <vue-select
                :options="industries"
                label="name"
                v-model="org.industry_id"
                target="id"
                placeholder="Select Industry"
                name="industry_id"
              />
            </div>
            <div class="col-md-3 mb-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="location"
                  placeholder="Location"
                  name="location"
                  v-model="org.location"
                />
                <label for="location">Location</label>
              </div>
            </div>
            <div class="col-md-3 mb-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="zip_code"
                  placeholder="Zip Code"
                  name="zip_code"
                  v-model="org.zip_code"
                />
                <label for="zip_code">Zip Code</label>
              </div>
            </div>
            <div class="col-md-3 mb-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="website"
                  placeholder="Website"
                  name="website"
                  v-model="org.website"
                />
                <label for="website">Website</label>
              </div>
            </div>
            <div class="col-md-12 mb-4">
              <label>About</label>
              <div>
                <input type="hidden" name="about" class="form-control">
              </div>
              <tiny-mce v-model="org.about" />
            </div>
          </div>
          <hr />
          <h5 class="mt-4 mb-3">What type of organization are you?</h5>
          <div class="row">
            <div class="col-md-3" v-for="(type, i) in types" :key="`ogr-type-${i}`">
              <div class="shadow p-3">
                <input type="radio" :id="`org-type-${i}`" class="me-3" name="organization_type_id" :value="type.id" v-model="org.organization_type_id" />
                <label :for="`org-type-${i}`">
                  <h6>{{ type.name }}</h6>
                </label>
                <label :for="`org-type-${i}`">
                  <p class="mb-0">{{ type.description }}</p>
                </label>
              </div>
            </div>
          </div>
          <hr />
          <h5 class="mt-4">
            What best describes your role in the organization? Select all that
            apply.
          </h5>
          <div class="d-inline custom-checkbox" v-for="(role, i) in roles" :key="`org-role-${i}`">
            <input type="checkbox" :id="`org-role-${i}`" name="organization_role_ids" :value="role.id" v-model="org.organization_role_ids" />
            <label :for="`org-role-${i}`" class="badge bg-secondary me-2"
              >{{ role.name }}</label
            >
          </div>
        </div>
        <hr />
        <div class="mb-3">
          <input type="checkbox" id="org-terms" class="me-2" v-model="org.terms" />
          <label for="org-terms"
            >Please accept the Terms and Conditions to successfully register the
            organization.</label
          >
        </div>
        <div class="text-end" v-if="org.terms">
          <a href="#" class="btn btn-primary btn-sm" @click.prevent="submit"
            ><i class="fa fa-save me-2"></i>Proceed</a
          >
        </div>
      </div>
      <div class="card-body" v-if="loading">
        <loading />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      org: { organization_role_ids: []},
      loading: true,
      industries: [],
      types: [],
      roles: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/dashboard/organizations/create").then((response) => {
        this.industries = response.data.industries;
        this.org.organization_type_id = response.data.industries[0].id;
        this.types = response.data.types;
        this.roles = response.data.roles;
        this.loading = false;
      });
    },

    submit() {
      let data = {
        form_id: 'organization_form',
        name: this.org.name,
        registration_number: this.org.registration_number,
        email: this.org.email,
        address: this.org.address,
        industry_id: this.org.industry_id,
        location: this.org.location,
        zip_code: this.org.zip_code,
        about: this.org.about,
        website: this.org.website,
        cover: this.org.cover,
        logo: this.org.logo,
        organization_type_id: this.org.organization_type_id,
        organization_role_ids: this.org.organization_role_ids,
      }
      
      this.$axios.post('/api/v1/dashboard/organizations', data).then(response => {
        this.$router.push({ name: 'dashboard.organizations.projects.create', params: { id: response.data.id }})
      })
    }
  },
};
</script>
