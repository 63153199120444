<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="far fa-file-plus" style="font-size: 30px"></i>
      </div>
      <div class="ps-3">
        <h4 class="mb-0">Additionality</h4>
        <p class="text-muted mb-0">
          Represents a net environmental benefit and real mitigation of GHG
          emissions in excess of the baseline scenario.
        </p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submit" v-if="!loading" id="additionalities_form">
      <table class="table">
        <tbody>
          <tr
            v-for="(additionality, i) in additionalities"
            :key="`additionality-${i}`"
          >
            <td>
              <strong>{{ additionality.name }}</strong>
            </td>
            <td>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="`additionality-check-${i}`"
                  :value="additionality.id"
                  v-model="selected_ids"
                />
                <label
                  class="form-check-label"
                  :for="`additionality-check-${i}`"
                >
                  No
                </label>
              </div>
            </td>
            <td>
              <div v-if="selected_ids.indexOf(additionality.id) < 0">
                {{ additionality.description }}
              </div>
              <div v-else>
                <input
                  type="text"
                  placeholder="Offset Amount (Tonnes of CO2)"
                  v-model="selected[additionality.id]"
                  class="form-control"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-4">
        <a
          href="#"
          @click.prevent="
            $router.push({
              name: 'dashboard.organizations.projects.create.details',
            })
          "
          class="btn btn-primary btn-sm"
          ><i class="fa fa-arrow-left me-2"></i>Back</a
        >
        <button class="btn btn-primary btn-sm float-end">
          Proceed<i class="fa fa-arrow-right ms-2"></i>
        </button>
      </div>
    </form>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      additionalities: [],
      loading: true,
      selected: {},
      selected_ids: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(
          `/api/v1/dashboard/my-projects/create?step=4&org_id=${this.$route.params.id}`
        )
        .then((response) => {
          this.additionalities = response.data.additionalities;
          this.loading = false;
        });
    },

    submit() {
      let data = {
        selected: this.selected,
        org_id: this.$route.params.id,
        step: 4,
        form_id: "additionalities_form",
      };

      this.$axios.post("/api/v1/dashboard/my-projects", data).then(() => {
        this.$router.push({
          name: "dashboard.organizations.projects.create.benefits",
        });
      });
    },
  },
};
</script>
