<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="fad fa-list" style="font-size: 30px"></i>
      </div>
      <div class="ps-3">
        <h4 class="mb-0">Project Summary</h4>
        <p class="text-muted mb-0">
          Please provide information about your project
        </p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submit" id="summary_form" v-if="!loading">
      <div class="cover-image">
        <image-picker
          placeholder="/theme/default-cover.png"
          id="organization_cover"
          name="organization_cover_image"
          imageClass="org-cover-image"
          v-model="project.cover"
        />
      </div>
      <div class="main-info">
        <div class="d-flex">
          <div style="max-width: 150px; max-height: 150px">
            <image-picker
              placeholder="/assets/organization.png"
              id="organization_logo"
              name="organization_logo_image"
              imageClass="org-logo-image"
              v-model="project.logo"
            />
          </div>
          <div class="centered w-100">
            <div class="ms-3 w-100">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control w-100"
                  id="name"
                  placeholder="Project Name"
                  name="name"
                  v-model="project.name"
                />
                <label for="name">Project Name</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="Project Headline"
              name="headline"
              v-model="project.headline"
            />
            <label>Project Headline</label>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="Project Website"
              name="website"
              v-model="project.website"
            />
            <label>Project Website</label>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="form-floating">
            <select
              name="grouped"
              class="form-control"
              v-model="project.grouped"
            >
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
            <label>Is this a grouped project?</label>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="form-floating">
            <select
              name="regular_updates"
              class="form-control"
              v-model="project.regular_updates"
            >
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
            <label>Do you update information regularly?</label>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="form-floating">
            <select
              name="regular_updates"
              class="form-control"
              v-model="project.status"
            >
              <option value="pending">Pending</option>
              <option value="active">Active</option>
            </select>
            <label>Project Status</label>
          </div>
        </div>
        <div class="col-md-12 mb-4">
          <label>Project Description</label>
          <div>
            <input type="hidden" name="description" class="form-control" />
          </div>
          <tiny-mce v-model="project.description" />
        </div>
      </div>
      <div class="mt-4">
        <a
          href="#"
          @click.prevent="
            $router.push({
              name: 'dashboard.organizations.projects.create',
            })
          "
          class="btn btn-primary btn-sm"
          ><i class="fa fa-arrow-left me-2"></i>Back</a
        >
        <button class="btn btn-primary btn-sm float-end">
          Proceed<i class="fa fa-arrow-right ms-2"></i>
        </button>
      </div>
    </form>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      project: { status: "pending", grouped: 0, regular_updates: 1 },
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(
          `/api/v1/dashboard/my-projects/create?step=2&org_id=${this.$route.params.id}`
        )
        .then((response) => {
          if (response.data.project.name) {
            this.project = response.data.project;
          }

          this.loading = false;
        });
    },

    submit() {
      let data = {
        name: this.project.name,
        headline: this.project.headline,
        grouped: this.project.grouped,
        website: this.project.website,
        regular_updates: this.project.regular_updates,
        status: this.project.status,
        description: this.project.description,
        cover: this.project.cover,
        logo: this.project.logo,
        form_id: "summary_form",
        organization_id: this.$route.params.id,
        step: 2,
      };

      this.$axios.post("/api/v1/dashboard/my-projects", data).then(() => {
        this.$router.push({
          name: "dashboard.organizations.projects.create.details",
        });
      });
    },
  },
};
</script>
