<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="fal fa-check-square" style="font-size: 30px"></i>
      </div>
      <div class="ps-3">
        <h4 class="mb-0">Validation / Verification</h4>
        <p class="text-muted mb-0">
          What are the criteria for validation and verification? Who will validate/verify your project?
        </p>
      </div>
    </div>
    <hr />
    <div>
      <form @submit.prevent="submit">
        <table class="table">
          <tbody>
            <tr>
              <td>
                <strong
                  >Criteria for validation</strong
                >
              </td>
              <td>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input floet-end"
                    type="checkbox"
                    id="defaultCheckbox1"
                    autocomplete="off"
                  />
                  <label class="form-check-label" for="defaultCheckbox1"
                    >No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong
                  >Criteria for verification</strong
                >
              </td>
              <td>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input floet-end"
                    type="checkbox"
                    id="defaultCheckbox1"
                    autocomplete="off"
                  />
                  <label class="form-check-label" for="defaultCheckbox1"
                    >No</label
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-4">
          <a
            href="#"
            @click.prevent="
              $router.push({
                name: 'dashboard.organizations.projects.create.transition',
              })
            "
            class="btn btn-primary btn-sm"
            ><i class="fa fa-arrow-left me-2"></i>Back</a
          >
          <button class="btn btn-primary btn-sm float-end">
            Proceed<i class="fa fa-arrow-right ms-2"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submit() {
      this.$router.push({ name: 'dashboard.organizations.projects.create.mitigations' })
    },
  },
};
</script>
