<template>
  <div>
    <div class="d-flex">
      <div class="centered">
        <i class="fad fa-users" style="font-size: 30px"></i>
      </div>
      <div class="ps-3">
        <h4 class="mb-0">Authorized Representatives</h4>
        <p class="text-muted mb-0">
          A more detailed information about your project
        </p>
      </div>
    </div>
    <hr />
    <div v-if="!loading">
      <form @submit.prevent="submit" class="mb-4 border p-3" id="users_form">
        <h5 class="underlined">Add User</h5>
        <div class="row">
          <div class="col-md-5">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                placeholder="User's Email"
                name="email"
                v-model="add_user.email"
              />
              <label>User's Email</label>
            </div>
          </div>
          <div class="col-md-5">
            <vue-select
              :options="roles"
              label="name"
              target="id"
              placeholder="Select Role"
              v-model="add_user.role"
            />
          </div>
          <div class="col-md-2 centered">
            <button class="btn btn-primary btn-sm w-100">
              <i class="fa fa-plus me-2"></i>Add User
            </button>
          </div>
        </div>
      </form>
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="5">There is no data to display</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-4">
        <a
          href="#"
          @click.prevent="
            $router.push({
              name: 'dashboard.organizations.projects.create.location',
            })
          "
          class="btn btn-primary btn-sm"
          ><i class="fa fa-arrow-left me-2"></i>Back</a
        >
        <button class="btn btn-primary btn-sm float-end">
          Proceed<i class="fa fa-arrow-right ms-2"></i>
        </button>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      roles: [],
      loading: true,
      add_user: {}
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get("/api/v1/dashboard/organizations/create")
        .then((response) => {
          this.roles = response.data.roles;
          this.loading = false;
        });
    },

    submit() {
      let data = {
        form_id: "users_form",
        org_id: this.$route.params.id,
        step: 10,
        email: this.add_user.email,
        role: this.add_user.role,
      };

      this.$axios.post("/api/v1/dashboard/my-projects", data).then(() => {
        this.$router.push({
          name: "dashboard.organizations.projects.create.documentation",
        });
      });
    },
  },
};
</script>
